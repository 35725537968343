<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-card class="elevation-12 mx-auto my-10" max-width="40%">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>Registration code expired</v-toolbar-title>
        </v-toolbar>
        <p class="font-weight-regular ml-5 mt-5 mb-5 mr-5">
          This registration code has expired. Please contact
          <a href="mailto:submissions@marswrigleydata.com"
            >submissions@marswrigleydata.com</a
          >
        </p>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style></style>
